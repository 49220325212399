import { render, staticRenderFns } from "./tasaProveedoresGeneral.vue?vue&type=template&id=2f86b348&scoped=true&"
import script from "./tasaProveedoresGeneral.vue?vue&type=script&lang=js&"
export * from "./tasaProveedoresGeneral.vue?vue&type=script&lang=js&"
import style0 from "./tasaProveedoresGeneral.vue?vue&type=style&index=0&id=2f86b348&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f86b348",
  null
  
)

export default component.exports