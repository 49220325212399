<template>
	<div class="mt-20 md:mt-0">
		<div
			class="flex items-center justify-between mb-4 bg-verdedark w-full text-white font-bold fixed md:static"
		>
			<span class="ml-2 my-2">Tasa Proveedores General</span>
		</div>
		<div>
			<div class="flex justify-center my-12">
				<table class="table-auto">
					<thead>
						<tr>
							<td class="border-2 px-4 py-2 font-bold text-center">
								Proveedor
							</td>
							<td class="border-2 px-4 py-2 font-bold text-center">
								Tasa del Dia
							</td>
							<td class="border-2 px-4 py-2 font-bold text-center">Pivote</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(proveedor, k) in arrayProveedores" :key="k">
							<td class="border-2 px-4 py-2">
								<router-link
									class="hover:text-blue-500 hover:underline"
									:to="{
										name: 'InventarioProveedor',
										params: {
											id: proveedor.id,
											nombre: proveedor.nombre.replace(/\s/g, '_'),
											user: proveedor.user_id
										}
									}"
									>{{ firstLetterUpperCase(proveedor.nombre) }}</router-link
								>
							</td>
							<td class="border-2 px-4 py-2 text-center">
								<input
									ref="tasa"
									class="text-center appearance-none"
									type="text"
									@change="tasaCambioFormato(k)"
									@keypress.enter="nextRow(k)"
									v-model.lazy="proveedor.tasa_dia"
								/>
							</td>
							<td class="border-2 px-4 py-2 text-center">
								<div
									class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in mx-2"
								>
									<input
										type="checkbox"
										:name="'toggle' + k"
										:id="'toggle' + k"
										v-model="proveedor.pivote_usd"
										:checked="proveedor.pivote_usd"
										class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border appearance-none cursor-pointer focus:outline-none"
									/>
									<label
										for="toggle"
										class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
									></label>
								</div>
								<div>
									<p v-if="proveedor.pivote_usd">USD</p>
									<p v-else>Bs</p>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="my-10 flex justify-center">
			<boton-primario botonpText="Guardar Cambios" @click="guardarCambios" />
		</div>
	</div>
</template>
<script>
import BotonPrimario from "../components/botonPrimario.vue";
import {
	formato,
	firstLetterUpperCase,
	cambiarComasPuntos,
	cambiarPuntosComas,
	quitarPuntosCambiarComas
} from "../functions.js";

export default {
	components: {
		BotonPrimario
	},
	data() {
		return {
			arrayProveedores: [],
			arrayProveedoresOriginal: [],
			pivotUSDBoolean: false
		};
	},
	async created() {
		this.$store.state.isLoading = true;
		await this.tasaProveedoresGeneralQuery(1);
		this.$store.state.isLoading = false;
	},
	methods: {
		formato,
		firstLetterUpperCase,
		cambiarComasPuntos,
		cambiarPuntosComas,
		quitarPuntosCambiarComas,
		nextRow(index) {
			let newIndex = index + 1;
			if (this.arrayProveedores.length <= newIndex) {
				newIndex = 0;
			}
			this.$refs.tasa[newIndex].focus();
		},
		tasaCambioFormato(index) {
			if (!isNaN(parseFloat(this.arrayProveedores[index].tasa_dia))) {
				this.arrayProveedores[index].tasa_dia = formato(
					parseFloat(
						this.cambiarComasPuntos(this.arrayProveedores[index].tasa_dia)
					)
				);
			} else {
				this.arrayProveedores[index].tasa_dia = 0;
			}
		},
		async tasaProveedoresGeneralQuery(disponible) {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/tasaProveedoresGeneralQuery.gql"),
					variables: {
						show: disponible // Proveedores disponibles
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.arrayProveedores = data.data.tasaProveedoresGeneralQuery;
					this.arrayProveedores.map(
						(e, index) =>
							(this.arrayProveedores[index].tasa_dia = formato(e.tasa_dia))
					);
					this.arrayProveedoresOriginal = JSON.parse(
						JSON.stringify(this.arrayProveedores)
					);
					this.$store.state.isLoading = false;
				})
				.catch(error => {
					console.log(error);
					this.$store.state.isLoading = false;
				});
		},
		async guardarCambios() {
			if (confirm("¿Guardar cambios?")) {
				this.$store.state.isLoading = true;
				let arrayResult = this.arrayProveedores.filter(
					(e, index) =>
						parseFloat(this.quitarPuntosCambiarComas(e.tasa_dia)) !=
							parseFloat(
								this.quitarPuntosCambiarComas(
									this.arrayProveedoresOriginal[index].tasa_dia
								)
							) ||
						e.pivote_usd != this.arrayProveedoresOriginal[index].pivote_usd
				);

				arrayResult = arrayResult.map(e => {
					return {
						id: parseInt(e.id),
						tasa_dia: parseFloat(this.quitarPuntosCambiarComas(e.tasa_dia)),
						pivote_usd: e.pivote_usd
					};
				});
				if (arrayResult.length > 0) {
					await this.$apollo
						.mutate({
							mutation: require("@/graphql/mutations/guardarTasaCambioGeneral.gql"),
							variables: {
								tasaLocales: arrayResult
							}
						})
						.then(() => {
							this.$store.state.isLoading = false;
							alert("Cambios guardados con exito");
						})
						.catch(error => {
							this.$store.state.isLoading = false;
							console.log(error);
							alert("Error de comunicación, por favor vuelva a intentar.");
						});
				}
				this.$store.state.isLoading = false;
			}
		}
	}
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
</style>
